@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@font-face {
  font-family: "SegoeUI";
  src: url("../src/assets/fonts/SEGOEUI.TTF");
}

@font-face {
  font-family: "Metropolis";
  src: url("../src/assets/fonts/metropolis.regular.otf") format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
@import '~react-loading-skeleton/dist/skeleton.css';
/* purgecss end ignore */